import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, Box } from '@mui/material'
import { toastError } from 'app/utils/toastUtils'
import { getRawMaterialStockLevels } from 'app/services/rawMaterial.service'

const RealtimeStockReport = () => {
    const [stockReportArr, setStockReportArr] = useState([])
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const SWITCH_INTERVAL = 10000

    const handleGetStockLevels = async (currentPage) => {
        try {
            const { data: res } = await getRawMaterialStockLevels(currentPage)
            if (res && res.data) {
                setStockReportArr(res.data)
                setTotalPages(res.pagination?.totalPages || 1)
            } else {
                toastError('No data')
            }
        } catch (err) {
            toastError('failed to fetch stock levels')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetStockLevels(page)
    }, [page])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setPage((currentPage) => (currentPage >= totalPages ? 1 : currentPage + 1))
        }, SWITCH_INTERVAL)

        return () => clearInterval(intervalId)
    }, [totalPages])

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100vw',
                height: '100vh',
                boxSizing: 'border-box',
                overflow: 'hidden',
            }}
        >
            <Typography variant="h4" gutterBottom>
                Realtime Stock Report (page {page} / {totalPages})
            </Typography>

            <Table
                stickyHeader
                sx={{
                    width: '100vw',
                    '& td, & th': {
                        borderRight: '1px solid black',
                    },
                    '& tr': {
                        borderBottom: '2px solid black',
                    },
                    '& thead th': {
                        backgroundColor: '#f5f5f5',
                        fontWeight: 'bold',
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell rowSpan={2} align="center">
                            SL.NO.
                        </TableCell>
                        <TableCell rowSpan={2} align="center">
                            Rack No.
                        </TableCell>
                        <TableCell rowSpan={2} align="center">
                            Raw Material
                        </TableCell>
                        <TableCell rowSpan={2} align="center">
                            UOM
                        </TableCell>
                        <TableCell align="center">Min. Level</TableCell>
                        <TableCell align="center">Reorder Level</TableCell>
                        <TableCell align="center">Max. Level</TableCell>
                        <TableCell align="center">Current Stock</TableCell>
                        <TableCell align="center">Current Stock Status</TableCell>
                        <TableCell align="center">Pick Me First</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stockReportArr.length > 0 ? (
                        stockReportArr.map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell align="center">{index + 1 + page * 10 - 10}</TableCell>
                                <TableCell align="center">{item.rackNo ? item.rackNo : 'N/A'}</TableCell>
                                <TableCell align="center">{item.rawMaterialName ? item.rawMaterialName : 'N/A'}</TableCell>
                                <TableCell align="center">{item.unit ? item.unit : 'N/A'}</TableCell>
                                <TableCell align="center">{item.minLevel ? item.minLevel : 'N/A'}</TableCell>
                                <TableCell align="center">{item.reorderLevel ? item.reorderLevel : 'N/A'}</TableCell>
                                <TableCell align="center">{item.maxLevel ? item.maxLevel : 'N/A'}</TableCell>
                                <TableCell
                                    align="center"
                                    sx={{
                                        backgroundColor: item.minLevel < item.reorderLevel ? 'red' : item.minLevel === item.reorderLevel ? 'yellow' : 'inherit',
                                        color: item.minLevel <= item.reorderLevel ? 'white' : 'inherit',
                                    }}
                                >
                                    {item.currentStock ? item.currentStock : 'N/A'}
                                </TableCell>
                                <TableCell align="center">{item.closing?.quantity || 0}</TableCell>
                                <TableCell align="center">{item.closing?.value || 0}</TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell align="center">No data available.</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    )
}

export default RealtimeStockReport
