import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'

import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'

import moment from 'moment'
import { toastError } from 'app/utils/toastUtils'
import { generateExcelForDailyStockReport, getAllRawMaterial, getDailyStockReportFromDate, getDailyStockReportFromDates, getSingleRawMaterialReport } from 'app/services/rawMaterial.service'

import Select from 'react-select'
export default function DailyStockReport() {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'))

    const [stockReportArr, setStockReportArr] = useState([])

    const [rawMaterialArr, setRawMaterialArr] = useState([])

    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([])

    const handleGetStockReport = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            console.log(selectedRawMaterialArr, '@@@@')
            if (selectedRawMaterialArr && selectedRawMaterialArr.every((el) => el.value != '')) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map((el) => el.value))}`
            }
            let { data: res } = await getDailyStockReportFromDates(query)
            if (res) {
                console.log(res)
                setStockReportArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetStockReportExcel = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            if (selectedRawMaterialArr && selectedRawMaterialArr.every((el) => el.value != '')) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map((el) => el.value))}`
            }

            const reportService = generateExcelForDailyStockReport(query)

            const { data } = await reportService.fetchData()
            console.log('daily stock data:\n', data)

            reportService.downloadExcel()
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetStockReportExcelSinglePage = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            console.log(selectedRawMaterialArr, '@@@@')
            if (selectedRawMaterialArr && selectedRawMaterialArr.every((el) => el.value != '')) {
                query = query + `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map((el) => el.value))}`
            }
            getSingleRawMaterialReport(query)
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial()
            if (res) {
                let tempArr = [...res.data.map((el) => ({ label: el.name, value: el._id }))]
                setRawMaterialArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetStockReport()
        handleGetRawMaterials()
    }, [])

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const TableHeadCell = styled(TableCell)(({ theme }) => ({
        backgroundColor: '#ededed',
        fontWeight: 'bold',
        color: '#000',
        fontSize: '14px',
        padding: '12px 16px',
        borderRight: '1px solid #bbb',
        borderBottom: '2px solid #999aaa',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const TableBodyCell = styled(TableCell)(({ theme }) => ({
        padding: '8px 16px',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const StyledTable = styled(Table)(({ theme }) => ({
        borderCollapse: 'collapse',
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& .MuiTableRow-root:hover': {
            backgroundColor: '#f5f5f5',
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Daily Stock Report'}>
                <Typography sx={{ mt: 3 }}>Select Dates</Typography>

                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={2}>
                        <TextField type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate} label="Start Date" sx={{ width: 250, mt: 2 }} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3}>
                        <TextField InputLabelProps={{ shrink: true }} type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate} label="End Date" sx={{ width: 250, mt: 2, ml: 12 }} />
                    </Grid>
                    <Grid item md={3}>
                    <Typography sx={{ mt: 3 }}>Raw Material</Typography>
                        <Select isMulti value={selectedRawMaterialArr} options={rawMaterialArr} placeholder="Raw Materials" onChange={(e) => setSelectedRawMaterialArr(e)} sx={{ ml: 12 }}  />
                    </Grid>
                </Grid>

                <Button onClick={() => handleGetStockReport()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Search
                </Button>
                <Button onClick={() => handleGetStockReportExcel()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Daily Report
                </Button>
                <Button onClick={() => handleGetStockReportExcelSinglePage()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Monthly Report
                </Button>
            </SimpleCard>
            {stockReportArr &&
                stockReportArr.length > 0 &&
                stockReportArr.map((el, index) => {
                    return (
                        <div style={{ marginTop: 10 }} key={index}>
                            <SimpleCard
                                title={
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            padding: '8px 0',
                                        }}
                                    >
                                        {`Report Date: ${moment(el.reportDate).format('MMMM DD, YYYY')}`}
                                    </Typography>
                                }
                            >
                                {/* <Box sx={{ height: '450px', overflow: 'auto', mt: 2, borderRadius: 1 }}>
                                    <StyledTable key={index} sx={{ minWidth: 650 }} aria-label="stock report table">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeadCell>#</TableHeadCell>
                                                <TableHeadCell>Part Name</TableHeadCell>
                                                <TableHeadCell>Part No</TableHeadCell>
                                                <TableHeadCell>Sub Category</TableHeadCell>
                                                <TableHeadCell>Opening Stock</TableHeadCell>
                                                <TableHeadCell>Stock In</TableHeadCell>
                                                <TableHeadCell>Stock Out</TableHeadCell>
                                                <TableHeadCell>Closing Stock</TableHeadCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {el.dataArr &&
                                                el.dataArr.length > 0 &&
                                                el.dataArr.map((row, indexX) => (
                                                    <TableRow key={`${index}_${indexX}`}>
                                                        <TableBodyCell component="th" scope="row">
                                                            {indexX + 1}
                                                        </TableBodyCell>
                                                        <TableBodyCell>{row?.partName}</TableBodyCell>
                                                        <TableBodyCell>{row?.partNo}</TableBodyCell>
                                                        <TableBodyCell>{row?.subcategoryName}</TableBodyCell>
                                                        <TableBodyCell>{row?.openingStock}</TableBodyCell>
                                                        <TableBodyCell>{row?.stockIn}</TableBodyCell>
                                                        <TableBodyCell>{row?.stockOut}</TableBodyCell>
                                                        <TableBodyCell>{row?.closingStock}</TableBodyCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </StyledTable>
                                </Box> */}

                                <Box sx={{ height: '450px', overflow: 'auto', mt: 2, borderRadius: 1 }}>
                                    <StyledTable key={index} sx={{ minWidth: 650 }} aria-label="stock report table">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeadCell rowSpan={2}>Date</TableHeadCell>
                                                <TableHeadCell rowSpan={2}>Particulars</TableHeadCell>
                                                <TableHeadCell rowSpan={2}>Vch Type</TableHeadCell>
                                                <TableHeadCell rowSpan={2}>Vch No.</TableHeadCell>
                                                <TableHeadCell colSpan={2}>Inwards</TableHeadCell>
                                                <TableHeadCell colSpan={2}>Outwards</TableHeadCell>
                                                <TableHeadCell colSpan={2}>Closing</TableHeadCell>
                                            </TableRow>
                                            <TableRow>
                                                {/* inwards subheaders */}
                                                <TableHeadCell>Quantity</TableHeadCell>
                                                <TableHeadCell>Value</TableHeadCell>
                                                {/* outwards subheaders */}
                                                <TableHeadCell>Quantity</TableHeadCell>
                                                <TableHeadCell>Value</TableHeadCell>
                                                {/* closing subheaders */}
                                                <TableHeadCell>Quantity</TableHeadCell>
                                                <TableHeadCell>Value</TableHeadCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {el.dataArr &&
                                                el.dataArr.length > 0 &&
                                                el.dataArr.map((row, indexX) => (
                                                    <TableRow key={`${index}_${indexX}`}>
                                                        <TableBodyCell>{moment(el.reportDate).format('DD/MM/YYYY')}</TableBodyCell>
                                                        <TableBodyCell>{row?.partName}</TableBodyCell>
                                                        <TableBodyCell>{row?.subcategoryName}</TableBodyCell>
                                                        <TableBodyCell>{row?.partNo}</TableBodyCell>
                                                        {/* inwards data */}
                                                        <TableBodyCell>{row?.openingStock || 0}</TableBodyCell>
                                                        <TableBodyCell>0</TableBodyCell>
                                                        {/* outwards data */}
                                                        <TableBodyCell>{row?.stockOut || 0}</TableBodyCell>
                                                        <TableBodyCell>0</TableBodyCell>
                                                        {/* closing data */}
                                                        <TableBodyCell>{row?.closingStock || 0}</TableBodyCell>
                                                        <TableBodyCell>0</TableBodyCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </StyledTable>
                                </Box>
                            </SimpleCard>
                        </div>
                    )
                })}
        </Container>
    )
}
