import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/RawMaterialSubCategory`

export const addRawMaterialSubCategory = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllRawMaterialSubCategory = () => {
    return axios.get(`${url}`)
}

export const deleteRawMaterialSubCategory = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getRawMaterialSubCategoryById = async (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const updateRawMaterialSubCategory = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}