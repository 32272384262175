import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import moment from 'moment'
import { getProductionPlanning, exportProductionPlansToExcel } from '../../../services/MouldingPlanning.service'
import { toastError } from 'app/utils/toastUtils'
import { useNavigate } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function ViewProductionPlanning() {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'))
    const [productionPlans, setProductionPlans] = useState([])

    const navigate=useNavigate()

    useEffect(() => {
        fetchProductionPlans()
    }, [])

    const fetchProductionPlans = async () => {
        try {
            const query = `startDate=${startDate}&endDate=${endDate}`
            const { data: res } = await getProductionPlanning(query)
            if (res) {
                setProductionPlans(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleExportToExcel = async () => {
        try {
            const query = `startDate=${startDate}`
            await exportProductionPlansToExcel(query)
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="View Production Plans">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography sx={{ mt: 3 }}>Select Date Range</Typography>
                    <Box>
                        <Button variant="contained" sx={{mr:2}} color="primary" onClick={()=>navigate("/admin/planning")}>
                            Add New
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleExportToExcel}>
                            Export to Excel
                        </Button>
                    </Box>
                </Box>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={3}>
                        <TextField
                            type="date"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                            label="Start Date"
                            sx={{ width: 250, mt: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            type="date"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                            label="End Date"
                            sx={{ width: 250, mt: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                </Grid>
                <Button onClick={fetchProductionPlans} variant="contained" color="primary" sx={{ mt: 5 }}>
                    Search
                </Button>
            </SimpleCard>

            {productionPlans.length > 0 && (
                <SimpleCard title="Production Plans" sx={{ mt: 5 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Machine</TableCell>
                                <TableCell>Mould</TableCell>
                                <TableCell>Shift A</TableCell>
                                <TableCell>Shift B</TableCell>
                                <TableCell>Total Production</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {productionPlans.map((plan, index) => (
                                <TableRow key={index}>
                                    <TableCell>{plan.dateCompareString}</TableCell>
                                    <TableCell>{plan.productName}</TableCell>
                                    <TableCell>{plan.machineName}</TableCell>
                                    <TableCell>{plan.mouldName}</TableCell>
                                    <TableCell>{plan.shiftAPlannedProductionQty}</TableCell>
                                    <TableCell>{plan.shiftBPlannedProductionQty}</TableCell>
                                    <TableCell>{plan.totalPlannedProduction}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </SimpleCard>
            )}
        </Container>
    )
}
