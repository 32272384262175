import React, {useEffect, useState} from 'react'
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress,} from '@mui/material'
import { toastError } from "app/utils/toastUtils";
import { MachineReport as fetchMachineReport } from "app/services/machine.service"

const MachineReport = () => {

    const [machineReportArr, setMachineReportArr] = useState([]);
    const [loading, setLoading] = useState(true)

    //fetch the machine report data
    const handleFetchMachineReport = async() => {
        try{
            const {data: res} = await fetchMachineReport();
            console.log(res, "machine report");
            if(res){
                setMachineReportArr(res.data);
            }
        } catch(err) {
            console.error("Error fetching machine report:", err);
            toastError("Failed to fetch machine report");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        handleFetchMachineReport();
    }, []);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    backgroundColor: "white",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return(
        <Box
        sx={{
            padding: 2,
            backgroundColor: "#f5f5f5",
            backgroundColor: "white",
            minHeight: "100vh",
        }}
        >
            <Typography variant='h4' color="textScondary" sx={{ marginBottom: 4 }}>
                Machine Report
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}>
                            <TableCell align="center">SL.NO.</TableCell>
                            <TableCell align="center">Name</TableCell>
                            <TableCell align="center">Machine Type</TableCell>
                            <TableCell align="center">Description</TableCell>
                            <TableCell align="center">Capacity/Spec</TableCell>
                            <TableCell align="center">Identification No.</TableCell>
                            <TableCell align="center">Make</TableCell>
                            <TableCell align="center">Location</TableCell>
                            <TableCell align="center">Installation On</TableCell>
                            <TableCell align="center">Remarks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {machineReportArr.length > 0 ? (
                            machineReportArr.map((item, index) => (
                                <TableRow key={item._id}>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{item.name || "N/A"}</TableCell>
                                    <TableCell align="center">{item.machineType || "N/A"}</TableCell>
                                    <TableCell align="center">{item.description || "N/A"}</TableCell>
                                    <TableCell align="center">{item.capacityOrSpec || "N/A"}</TableCell>
                                    <TableCell align="center">{item.identificationNo || "N/A"}</TableCell>
                                    <TableCell align="center">{item.make || "N/A"}</TableCell>
                                    <TableCell align="center">{item.location || "N/A"}</TableCell>
                                    <TableCell align="center">{item.InstallationOn || "N/A"}</TableCell>
                                    <TableCell align="center">{item.remarks || "N/A"}</TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={10} align="center">
                                    No data available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default MachineReport;