import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import LoginGuard from 'app/auth/LoginGuard'
import DownloadQr from '../admin/MrnQc/DownloadQr'
import DownloadItQr from '../admin/ITProducts/DownloadItQr'
import MachineQr from '../admin/Machine/MachineQr'
import RealtimeStockReport from '../admin/Report/RealtimeStockReport'
import MachineReport from '../admin/Report/MachineReport'

const NotFound = Loadable(lazy(() => import('./NotFound')))
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')))
const JwtLogin = Loadable(lazy(() => import('./login/JwtLogin')))
const JwtRegister = Loadable(lazy(() => import('./register/JwtRegister')))

const sessionRoutes = [
    {
        path: '/session/signup',
        element: (
            <LoginGuard>
                <JwtRegister />
            </LoginGuard>
        ),
    },
    {
        path: '/session/signin',
        element: (
            <LoginGuard>
                <JwtLogin />
            </LoginGuard>
        ),
    },
    {
        path: '/session/forgot-password',
        element: (
            <LoginGuard>
                <ForgotPassword />
            </LoginGuard>
        ),
    },
    {
        path: '/session/realtime-stock',
        element: <RealtimeStockReport />, 
    },
    {
        path: '/session/machine-report',
        element: <MachineReport />, 
    },
    {
        path: '/DownloadQr/:id',
        element: <DownloadQr />,
    },
    {
        path: '/machineqr',
        element: <MachineQr />,
    },
    {
        path: '/DownloadItQr',
        element: <DownloadItQr />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
