import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import Select from 'react-select'
import { getAllProduct } from '../../../services/product.service'
import { getAllRawMaterial } from '../../../services/rawMaterial.service'
import { getProductRecipeById, addProductRecipe, updateProductRecipe } from '../../../services/ProductRecipe.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useLocation } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const SelectionSection = styled('div')(({ theme }) => ({
    height: '200px',
    overflow: 'hidden',
    marginBottom: '20px',
}))

export default function AddProductRecipe() {
    const [productArr, setProductArr] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [selectedRawMaterials, setSelectedRawMaterials] = useState([])
    const [recipeData, setRecipeData] = useState([])
    const [productError, setProductError] = useState(false)
    const [rawMaterialError, setRawMaterialError] = useState(false)
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const recipeId = query.get('id')

    useEffect(() => {
        fetchProducts()
        fetchRawMaterials()
        if (recipeId) {
            fetchRecipeById(recipeId)
        }
    }, [recipeId])

    const fetchProducts = async () => {
        try {
            let { data: res } = await getAllProduct()
            if (res) {
                let tempArr = res.data.map(el => ({ label: el.name, value: el._id }))
                setProductArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const fetchRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial()
            if (res) {
                let tempArr = res.data.map(el => ({ label: el.name, value: el._id, unit: el.units, quantityPer: el.quantityPer }))
                setRawMaterialArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const fetchRecipeById = async (id) => {
        try {
            let { data: res } = await getProductRecipeById(id)
            if (res) {
                console.log(res,"@@")
                setSelectedProduct({ label: res.data.productName, value: res.data.productId })
                const rawMaterials = res.data.mouldingRawMaterialArr.map(el => ({
                    label: el.rawMaterialName,
                    value: el.rawMaterialId,
                    unit: el.unit,
                    quantityPer: el.quantityPer
                }))
                setSelectedRawMaterials(rawMaterials)
                const recipeData = rawMaterials.map(rawMaterial => ({
                    rawMaterial,
                    quantity: res.data.mouldingRawMaterialArr.find(el => el.rawMaterialId === rawMaterial.value).rawMaterialQty
                }))
                setRecipeData(recipeData)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleProductChange = (selectedOption) => {
        setSelectedProduct(selectedOption)
        setProductError(false)
    }

    const handleRawMaterialChange = (selectedOptions) => {
        setSelectedRawMaterials(selectedOptions)
        setRawMaterialError(false)
    }

    const handleSubmit = () => {
        if (!selectedProduct) {
            setProductError(true)
            return
        }
        if (selectedRawMaterials.length === 0) {
            setRawMaterialError(true)
            return
        }

        // Generate recipe data for the selected raw materials
        const data = selectedRawMaterials.map(rawMaterial => ({
            rawMaterial,
            quantity: ''
        }))
        setRecipeData(data)
    }

    const handleQuantityChange = (index, value) => {
        const updatedData = [...recipeData]
        updatedData[index].quantity = value
        setRecipeData(updatedData)
    }

    const handleFinalSubmit = async () => {
        try {
            let obj = {
                productId: selectedProduct?.value,
                productName: selectedProduct?.label,
                mouldingRawMaterialArr: recipeData.map(el => {
                    return {
                        rawMaterialId: el?.rawMaterial?.value,
                        rawMaterialName: el?.rawMaterial?.label,
                        rawMaterialQty: Number(el?.quantity),
                        unit: el?.rawMaterial?.unit
                    }
                }),
            }
            let res
            if (recipeId) {
                res = await updateProductRecipe(recipeId, obj)
            } else {
                res = await addProductRecipe(obj)
            }
            if (res) {
                toastSuccess(res.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="BOM" sx={{ mb: 2 }}>
                <SelectionSection>
                    <Typography sx={{ mt: 3 }}>Select Product and Raw Materials</Typography>
                    <Grid container columnSpacing={3} rowSpacing={3}>
                        <Grid item md={6}>
                            <Select
                                value={selectedProduct}
                                options={productArr}
                                placeholder="Select Product"
                                onChange={handleProductChange}
                                sx={{ mt: 2 }}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderColor: productError ? 'red' : base.borderColor,
                                    }),
                                }}
                            />
                            {productError && <Typography color="error">Please select a product</Typography>}
                        </Grid>
                        <Grid item md={6}>
                            <Select
                                isMulti
                                value={selectedRawMaterials}
                                options={rawMaterialArr}
                                placeholder="Select Raw Materials"
                                onChange={handleRawMaterialChange}
                                sx={{ mt: 2 }}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        borderColor: rawMaterialError ? 'red' : base.borderColor,
                                    }),
                                }}
                            />
                            {rawMaterialError && <Typography color="error">Please select at least one raw material</Typography>}
                        </Grid>
                    </Grid>
                    <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 5 }}>
                        Submit
                    </Button>
                </SelectionSection>
            </SimpleCard>

            {recipeData.length > 0 && (
                <SimpleCard title="Recipe Details" sx={{ mt: 10 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Raw Material</TableCell>
                                <TableCell>Quantity Per Unit</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>Quantity(gm/ml/pc)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {recipeData.map((data, index) => (
                                <TableRow key={data.rawMaterial.value}>
                                    <TableCell>{data.rawMaterial.label}</TableCell>
                                    <TableCell>{data.rawMaterial.quantityPer}</TableCell>
                                    <TableCell>{data.rawMaterial.unit}</TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={data.quantity}
                                            onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            sx={{ width: '100%' }}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button onClick={handleFinalSubmit} variant="contained" color="primary" sx={{ mt: 5 }}>
                        Final Submit
                    </Button>
                </SimpleCard>
            )}
        </Container>
    )
}
