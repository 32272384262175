import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/PendingStockGroup`

export const getAllPendingStockGroup = () => {
    return axios.get(`${url}`)
}

export const getById = async (id) => {
    return await axios.get(`${url}/getById/${id}`)
}

export const setStockLocation = async (obj) => {
    return await axios.post(`${url}/setStockLocation`, obj)
}

export const getStockLocation = async () => {
    return await axios.get(`${url}/getStockLocation`)
}

export const getRawMaterailStockById=async(id)=>{
    return await axios.get(`${url}/getRawMaterailStockById/${id}`)
}


export const getStockData = async () => {
    return await axios.get(`${url}/getstock`)
}


