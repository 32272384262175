import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField } from '@mui/material'
import moment from 'moment'
import Select from 'react-select'

import { toastError } from 'app/utils/toastUtils'
import { getAllRawMaterial, getRawItemsStockReport } from 'app/services/rawMaterial.service'

const AllRawItemsReport = () => {
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [filteredData, setFilteredData] = useState([])
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([])
    const [loading, setLoading] = useState(false)

    const handleGenerateReport = async () => {
        if (!startDate || !endDate) {
            toastError('select both start and end dates')
            return
        }
        try {
            setLoading(true)

            let queryString = `startDate=${startDate}&endDate=${endDate}`
            if (selectedRawMaterialArr.length > 0) {
                selectedRawMaterialArr.forEach((material) => {
                    queryString += `&rawMaterialIds=${material.value}`
                })
            }

            const { data: res } = await getRawItemsStockReport(queryString)
            if (res.success) {
                const formattedData = res.data.reduce((acc, curr) => {
                    acc[curr.date] = curr.items
                    return acc
                }, {})

                setFilteredData(formattedData)
            }
        } catch (error) {
            toastError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetRawMaterials()
    }, [])

    const handleGetRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial()
            if (res) {
                let tempArr = [...res.data.map((el) => ({ label: el.name, value: el._id }))]
                setRawMaterialArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const TableHeadCell = styled(TableCell)(({ theme }) => ({
        backgroundColor: '#ededed',
        fontWeight: 'bold',
        color: '#000',
        fontSize: '14px',
        padding: '12px 16px',
        borderRight: '1px solid #bbb',
        borderBottom: '2px solid #999aaa',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const TableBodyCell = styled(TableCell)(({ theme }) => ({
        padding: '8px 16px',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const StyledTable = styled(Table)(({ theme }) => ({
        borderCollapse: 'collapse',
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& .MuiTableRow-root:hover': {
            backgroundColor: '#f5f5f5',
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Raw Items Stock Report'} CustomStyle={{ marginBottom: '2rem' }}>
                <Typography sx={{ mt: 3 }}>Select Dates</Typography>

                <Grid container spacing={3}>
                    <Grid item md={2}>
                        <TextField type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate} label="Start Date" sx={{ width: 250, mt: 2 }} InputLabelProps={{ shrink: true }} disabled={loading} />
                    </Grid>
                    <Grid item md={3}>
                        <TextField InputLabelProps={{ shrink: true }} type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate} label="End Date" sx={{ width: 250, mt: 2, ml: 12 }} disabled={loading} />
                    </Grid>
                    <Grid item md={3}>
                        <Typography>Raw Material</Typography>
                        <Select
                            isMulti
                            value={selectedRawMaterialArr}
                            options={rawMaterialArr}
                            placeholder="Raw Materials"
                            onChange={(e) => {
                                console.log('material:', e)
                                setSelectedRawMaterialArr(e)
                            }}
                            sx={{ ml: 12 }}
                            isDisabled={loading}
                        />
                    </Grid>
                </Grid>

                <Button onClick={handleGenerateReport} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }} disabled={loading}>
                    {loading ? 'Generating...' : 'Generate Report'}
                </Button>
            </SimpleCard>

            {Object.keys(filteredData).length > 0 &&
                Object.entries(filteredData).map(([date, items]) => (
                    <SimpleCard key={date} title={`Stock Report - ${moment(date).format('DD MMM YYYY')}`} sx={{ mt: 3 }} CustomStyle={{ marginY: '1rem' }}>
                        <Box sx={{ overflow: 'auto', mt: 2, borderRadius: 1 }}>
                            <StyledTable sx={{ minWidth: 650 }} aria-label="raw items stock report table">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell rowSpan={2}>S.No</TableHeadCell>
                                        <TableHeadCell rowSpan={2}>Part No.</TableHeadCell>
                                        <TableHeadCell rowSpan={2}>Item Name</TableHeadCell>
                                        <TableHeadCell rowSpan={2}>Unit</TableHeadCell>
                                        <TableHeadCell colSpan={2}>Opening Stock</TableHeadCell>
                                        <TableHeadCell colSpan={2}>Material In</TableHeadCell>
                                        <TableHeadCell colSpan={2}>Outward Qty.</TableHeadCell>
                                        <TableHeadCell colSpan={2}>Balance</TableHeadCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableHeadCell>Qty.</TableHeadCell>
                                        <TableHeadCell>Amount</TableHeadCell>
                                        <TableHeadCell>Qty.</TableHeadCell>
                                        <TableHeadCell>Amount</TableHeadCell>
                                        <TableHeadCell>Qty.</TableHeadCell>
                                        <TableHeadCell>Amount</TableHeadCell>
                                        <TableHeadCell>Qty.</TableHeadCell>
                                        <TableHeadCell>Amount</TableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {items.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableBodyCell>{index + 1}</TableBodyCell>
                                            <TableBodyCell>{row.partNo}</TableBodyCell>
                                            <TableBodyCell>{row.partName}</TableBodyCell>
                                            <TableBodyCell>{row.unit}</TableBodyCell>
                                            <TableBodyCell>{row.openingStock?.qty?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.openingStock?.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.materialIn?.qty?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.materialIn?.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.outward?.qty?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.outward?.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.balance?.qty?.toFixed(2) || '0.00'}</TableBodyCell>
                                            <TableBodyCell>{row.balance?.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                        </Box>
                    </SimpleCard>
                ))}
        </Container>
    )
}

export default AllRawItemsReport
