import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, TextField, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import moment from 'moment'
import Select from 'react-select'
import { getAllProduct } from 'app/services/product.service'
import {  getMachineWithMouldData } from 'app/services/machine.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { addMouldingPlanning } from 'app/services/MouldingPlanning.service'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function ProductionPlanning() {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'))
    const [productArr, setProductArr] = useState([])
    const [selectedProductArr, setSelectedProductArr] = useState([])
    const [machineArr, setMachineArr] = useState([])

    const [productionData, setProductionData] = useState([])

    useEffect(() => {
        fetchProducts()
        fetchMachines()
    }, [])

    const fetchProducts = async () => {
        try {
            let { data: res } = await getAllProduct()
            if (res) {
                let tempArr = res.data.map(el => ({ label: el.name, value: el._id }))
                setProductArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const fetchMachines = async () => {
        try {
            let { data: res } = await getMachineWithMouldData()
            if (res) {
                console.log(res.data,"@@")
                setMachineArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleSearch = () => {
        // Generate production data for the selected date range and products
        const dates = []
        let currentDate = moment(startDate)
        while (currentDate <= moment(endDate)) {
            dates.push(currentDate.format('YYYY-MM-DD'))
            currentDate = currentDate.add(1, 'days')
        }
        console.log(selectedProductArr, "CHECK THIS OUT", machineArr)
        const data = selectedProductArr.map(product => ({
            product,
            machines: machineArr.filter(machine => machine.productIdArr.some(el => el.productId == product.value))?.map(el=>({...el,mouldArr:el?.mouldArr?.map(elx=>({...elx,cavityProductArr:elx?.cavityProductArr?.map((elz,index)=>({...elz,cavityNumber:index+1}))}))})),
            dates: dates.map(date => ({
                date,
                shiftA: '',
                shiftB: '',
                total: '',
                machine: null,
                mould:null,
                cavity:null,
                cavityArr:null
            }))
        }))

        setProductionData(data)
    }

    const handleInputChange = (productIndex, dateIndex, shift, value) => {
        const updatedData = [...productionData]
        updatedData[productIndex].dates[dateIndex][shift] = value
        updatedData[productIndex].dates[dateIndex].total =
            (parseInt(updatedData[productIndex].dates[dateIndex].shiftA) || 0) +
            (parseInt(updatedData[productIndex].dates[dateIndex].shiftB) || 0)
        setProductionData(updatedData)
    }

    const handleMachineChange = (productIndex, dateIndex, selectedMachine) => {
        const updatedData = [...productionData]
        updatedData[productIndex].dates[dateIndex].machine = selectedMachine;
        setProductionData(updatedData)
    }

    const handleMouldChange = (productIndex, dateIndex, selectedMould) => {
        const updatedData = [...productionData]
        updatedData[productIndex].dates[dateIndex].mould = selectedMould;


        if(selectedMould){
            
            updatedData[productIndex].dates[dateIndex].cavityArr = selectedMould.cavityProductArr;
        }
        
        console.log(selectedMould,updatedData)

        setProductionData(updatedData)
    }
    const handleCavityChange = (productIndex, dateIndex, cavity) => {
        const updatedData = [...productionData]
        updatedData[productIndex].dates[dateIndex].cavity = cavity;


       
        

        setProductionData(updatedData)
    }

    const handleDeleteProduct = (productIndex) => {
        const updatedData = productionData.filter((_, index) => index !== productIndex);
        setProductionData(updatedData);
    }

    const handleSubmit = async () => {
        try {
            console.log(productionData,"@@@@@")
            let obj = {
                productionArr: productionData?.map(el => {
                    return {
                        productObj:{productId:el.product.value,name:el.product.label},
                        datesArr:el?.dates?.map(elx=>{
                            return{
                                date:elx.date,
                                hourlyProdQty:elx.hourlyProdQty,
                                machineObj:{
                                    machineId:elx.machine.value,
                                    name:elx.machine.label,
                                    loadingCapacity:elx.machine.loadingCapacity,
                                },
                                mouldObj:{
                                    mouldId:elx.mould.value,
                                    name:elx.mould.label,
                                    cycleTime:elx.mould.cycleTime,
                                    mouldNumber:elx.mould.mouldNumber,
                                    cavity:elx.cavity.value,
                                },
                                
                                shiftA:Number(elx.shiftA),
                                shiftB:Number(elx.shiftB),
                                hourlyProduction:Number(elx.hourlyProdQty),
                                totalProduction:Number(elx.total)
                            }
                        })

                    }
                })
            }

            let {data:res}=await addMouldingPlanning(obj);
            if(res){
                toastSuccess(res.message);
            }




        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="Production Planning">
                <Typography sx={{ mt: 3 }}>Select Date Range</Typography>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={3}>
                        <TextField
                            type="date"
                            onChange={(e) => setStartDate(e.target.value)}
                            value={startDate}
                            label="Start Date"
                            sx={{ width: 250, mt: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            type="date"
                            onChange={(e) => setEndDate(e.target.value)}
                            value={endDate}
                            label="End Date"
                            sx={{ width: 250, mt: 2 }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Typography sx={{ mt: 3 }}>Select Products</Typography>
                        <Select
                            isMulti
                            value={selectedProductArr}
                            options={productArr}
                            placeholder="Products"
                            onChange={(e) => setSelectedProductArr(e)}
                            sx={{ mt: 2 }}
                        />
                    </Grid>
                </Grid>
                <Button onClick={handleSearch} variant="contained" color="primary" sx={{ mt: 5 }}>
                    Search
                </Button>
            </SimpleCard>

            {productionData.length > 0 && (
                <SimpleCard title="Daily Production Input">
                    {productionData.map((productData, productIndex) => (
                        <Box key={productData.product.value} sx={{ mt: 5 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                <Typography variant="h6">{productData.product.label}</Typography>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => handleDeleteProduct(productIndex)}
                                >
                                    Delete Product
                                </Button>
                            </Box>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Hourly Production QTY</TableCell>
                                        <TableCell>Shift A</TableCell>
                                        <TableCell>Shift B</TableCell>
                                        <TableCell>Total Plan Qty</TableCell>
                                        <TableCell>Machine</TableCell>
                                        <TableCell>Mould</TableCell>
                                        <TableCell>Cavity</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {productData.dates.map((dateData, dateIndex) => (
                                        <TableRow key={dateData.date}>
                                            <TableCell>{dateData.date}</TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={dateData.hourlyProdQty}
                                                    onChange={(e) => handleInputChange(productIndex, dateIndex, 'hourlyProdQty', e.target.value)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={dateData.shiftA}
                                                    onChange={(e) => handleInputChange(productIndex, dateIndex, 'shiftA', e.target.value)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    type="number"
                                                    value={dateData.shiftB}
                                                    onChange={(e) => handleInputChange(productIndex, dateIndex, 'shiftB', e.target.value)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>
                                            <TableCell>{dateData.total}</TableCell>
                                            <TableCell>
                                                <Select
                                                    value={dateData.machine}
                                                    options={productData.machines.map(machine => ({ ...machine,label: machine.name, value: machine._id }))}
                                                    placeholder="Machine"
                                                    onChange={(e) => handleMachineChange(productIndex, dateIndex, e)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={dateData.mould}
                                                    options={dateData?.machine?.mouldArr?.map(mould => ({ ...mould,label: mould.name, value: mould._id }))}
                                                    placeholder="Mould"
                                                    onChange={(e) => handleMouldChange(productIndex, dateIndex, e)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={dateData.cavity}
                                                    options={dateData?.cavityArr?.filter(elz=>elz.productId==productData.product.value)?.map(mould => ({ ...mould,label: mould.cavityNumber, value: mould.cavityNumber }))}
                                                    placeholder="cavity"
                                                    onChange={(e) => handleCavityChange(productIndex, dateIndex, e)}
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    ))}
                    <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 5 }}>
                        Submit
                    </Button>
                </SimpleCard>
            )}
        </Container>
    )
}
