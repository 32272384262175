import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'
import moment from 'moment'
import Select from 'react-select'
import { toastError } from 'app/utils/toastUtils'
import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { generateExcelForDailyStockReport, getAllRawMaterial, getDailyStockReportFromDate, getDailyStockReportFromDates, getSingleRawMaterialReport, getItemWiseDailyReport } from 'app/services/rawMaterial.service'

const ItemWiseReport = () => {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'))
    const [stockReportArr, setStockReportArr] = useState([])
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [selectedRawMaterial, setSelectedRawMaterial] = useState(null)

    const handleGetStockReport = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            if (selectedRawMaterial && selectedRawMaterial.value) {
                query = query + `&partIdArr=${JSON.stringify([selectedRawMaterial.value])}`
            }
            let { data: res } = await getDailyStockReportFromDates(query)
            if (res) {
                console.log(res)
                setStockReportArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetStockReportExcel = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            if (selectedRawMaterial && selectedRawMaterial.value) {
                query = query + `&partIdArr=${JSON.stringify([selectedRawMaterial.value])}`
            }

            // const reportService = getItemWiseDailyReport(query)
            // const { data } = await reportService.fetchData()
            // console.log(data.data)
            // reportService.downloadExcel()
            await getItemWiseDailyReport(query)
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial()
            if (res) {
                let tempArr = [...res.data.map((el) => ({ label: el.name, value: el._id }))]
                setRawMaterialArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetRawMaterials()
    }, [])

    useEffect(() => {
        if (selectedRawMaterial) {
            handleGetStockReport()
        }
    }, [selectedRawMaterial])

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const TableHeadCell = styled(TableCell)(({ theme }) => ({
        backgroundColor: '#ededed',
        fontWeight: 'bold',
        color: '#000',
        fontSize: '14px',
        padding: '12px 16px',
        borderRight: '1px solid #bbb',
        borderBottom: '2px solid #999aaa',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const TableBodyCell = styled(TableCell)(({ theme }) => ({
        padding: '8px 16px',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const StyledTable = styled(Table)(({ theme }) => ({
        borderCollapse: 'collapse',
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& .MuiTableRow-root:hover': {
            backgroundColor: '#f5f5f5',
        },
    }))

    const TotalCell = styled(TableCell)(({ theme }) => ({
        padding: '8px 16px',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        backgroundColor: '#ededed',
        fontWeight: 'bold',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Daily Stock Report'}>
                <Typography sx={{ mt: 3 }}>Select Dates</Typography>

                <Grid container columnSpacing={3} rowSpacing={3}>
                    <Grid item md={2}>
                        <TextField type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate} label="Start Date" sx={{ width: 250, mt: 2 }} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item md={3}>
                        <TextField InputLabelProps={{ shrink: true }} type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate} label="End Date" sx={{ width: 250, mt: 2, ml: 2 }} />
                    </Grid>
                    <Grid item md={3}>
                        <label>Raw Material</label>
                        <Select value={selectedRawMaterial} options={rawMaterialArr} placeholder="Select Raw Material" onChange={(e) => setSelectedRawMaterial(e)} isClearable />
                    </Grid>
                </Grid>

                <Button onClick={() => handleGetStockReport()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Search
                </Button>
                <Button onClick={() => handleGetStockReportExcel()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Download Report
                </Button>
            </SimpleCard>
            {stockReportArr && stockReportArr.length > 0 && (
                <div style={{ marginTop: 10 }}>
                    <SimpleCard
                        title={
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#000',
                                    fontWeight: 'bold',
                                    padding: '8px 0',
                                }}
                            >
                                {`${stockReportArr[0]?.dataArr[0]?.partName} (${stockReportArr[0]?.dataArr[0]?.partNo})`}
                            </Typography>
                        }
                    >
                        <Box sx={{ height: '450px', overflow: 'auto', mt: 2, borderRadius: 1 }}>
                            <StyledTable sx={{ minWidth: 650 }} aria-label="stock report table">
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell rowSpan={2}>Date</TableHeadCell>
                                        <TableHeadCell rowSpan={2}>Particulars</TableHeadCell>
                                        {/* <TableHeadCell rowSpan={2}>Vch Type</TableHeadCell> */}
                                        {/* <TableHeadCell rowSpan={2}>Vch No.</TableHeadCell> */}
                                        <TableHeadCell colSpan={2}>Inwards</TableHeadCell>
                                        <TableHeadCell colSpan={2}>Outwards</TableHeadCell>
                                        <TableHeadCell colSpan={2}>Closing</TableHeadCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableHeadCell>Quantity</TableHeadCell>
                                        <TableHeadCell>Value</TableHeadCell>
                                        <TableHeadCell>Quantity</TableHeadCell>
                                        <TableHeadCell>Value</TableHeadCell>
                                        <TableHeadCell>Quantity</TableHeadCell>
                                        <TableHeadCell>Value</TableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {stockReportArr.map((el, index) => (
                                        <TableRow key={index}>
                                            <TableBodyCell>{moment(el.reportDate).format('DD/MM/YYYY')}</TableBodyCell>
                                            <TableBodyCell>{el.dataArr[0]?.partName}</TableBodyCell>
                                            {/* <TableBodyCell>{el.dataArr[0]?.subcategoryName}</TableBodyCell> */}
                                            {/* <TableBodyCell>{el.dataArr[0]?.partNo}</TableBodyCell> */}
                                            {/* inwards data */}
                                            <TableBodyCell>{el.dataArr[0]?.openingStock || 0}</TableBodyCell>
                                            <TableBodyCell>{el.dataArr[0]?.openingStock * el.dataArr[0]?.rmRate || 0}</TableBodyCell>
                                            {/* outwards data */}
                                            <TableBodyCell>{el.dataArr[0]?.stockOut || 0}</TableBodyCell>
                                            <TableBodyCell>{el.dataArr[0]?.stockOut * el.dataArr[0]?.rmRate || 0}</TableBodyCell>
                                            {/* closing data */}
                                            <TableBodyCell>{el.dataArr[0]?.closingStock || 0}</TableBodyCell>
                                            <TableBodyCell>{el.dataArr[0]?.closingStock * el.dataArr[0]?.rmRate || 0}</TableBodyCell>
                                        </TableRow>
                                    ))}

                                    {/* total row */}
                                    <TableRow>
                                        <TotalCell>-</TotalCell>
                                        <TotalCell>Total</TotalCell>
                                        <TotalCell>{stockReportArr.reduce((sum, el) => sum + (el.dataArr[0]?.openingStock || 0), 0)}</TotalCell>
                                        <TotalCell>{stockReportArr.reduce((sum, el) => sum + (el.dataArr[0]?.openingStock * el.dataArr[0]?.rmRate || 0), 0)}</TotalCell>
                                        <TotalCell>{stockReportArr.reduce((sum, el) => sum + (el.dataArr[0]?.stockOut || 0), 0)}</TotalCell>
                                        <TotalCell>{stockReportArr.reduce((sum, el) => sum + (el.dataArr[0]?.stockOut * el.dataArr[0]?.rmRate || 0), 0)}</TotalCell>
                                        <TotalCell>{stockReportArr.reduce((sum, el) => sum + (el.dataArr[0]?.closingStock || 0), 0)}</TotalCell>
                                        <TotalCell>{stockReportArr.reduce((sum, el) => sum + (el.dataArr[0]?.closingStock * el.dataArr[0]?.rmRate || 0), 0)}</TotalCell>
                                    </TableRow>
                                </TableBody>
                            </StyledTable>
                        </Box>
                    </SimpleCard>
                </div>
            )}
        </Container>
    )
}

export default ItemWiseReport
