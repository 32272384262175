import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/ProductRecipe`

export const addProductRecipe = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllProductRecipe = () => {
    return axios.get(`${url}`)
}

export const deleteProductRecipe = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getProductRecipeById = async (id) => {
    return axios.get(`${url}/getById/${id}`)
}


export const updateProductRecipe = async (id,obj) => {
    return axios.patch(`${url}/updateById/${id}`,obj)
}
