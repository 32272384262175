import { Card, CardContent, Typography, CardActions, Button, Box } from '@mui/material'
import './ReportStyles.css'

const CardComponent = ({ category, title, redirectPath }) => {
    return (
        <CardContent>
            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{category}</Typography>
            <Typography variant="h5" component="p">
                {title}
            </Typography>

            <CardActions style={{ padding: '4px 0' }}>
                <Button variant="contained" size="small" href={redirectPath} style={{ fontSize: '14px' }}>
                    Check
                </Button>
            </CardActions>
        </CardContent>
    )
}

const cardsContent = [
    { category: 'Item Wise', title: 'Daily Stock', redirectPath: '/admin/item-report' },
    { category: 'Item Wise', title: 'Monthly Stock', redirectPath: '/admin/items-monthly-report' },
    { category: 'All Items', title: 'Daily Stock', redirectPath: '/' },
    { category: 'All Items', title: 'Monthly Stock', redirectPath: '/admin/all-monthly-report' },
    { category: 'All Items', title: 'Realtime Stock', redirectPath: '/session/realtime-stock' },
    { category: 'All Items', title: 'Machine Report', redirectPath: '/session/machine-report' },
    { category: 'placeholder 3', title: 'Raw Items Report', redirectPath: '/admin/all-rawitems-report' },
    { category: 'placeholder 4', title: 'placeholder 14', redirectPath: '/' },
    { category: 'placeholder 5', title: 'placeholder 15', redirectPath: '/' },
    { category: 'placeholder 6', title: 'placeholder 16', redirectPath: '/' },
    { category: 'placeholder 7', title: 'placeholder 17', redirectPath: '/' },
    { category: 'placeholder 8', title: 'placeholder 18', redirectPath: '/' },
    { category: 'placeholder 9', title: 'placeholder 19', redirectPath: '/' },
    { category: 'placeholder 10', title: 'placeholder 20', redirectPath: '/' },
]

const StocksReport = () => {
    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '16px',
                padding: '16px',
                '@media (max-width: 1200px)': {
                    gridTemplateColumns: 'repeat(3, 1fr)',
                },
                '@media (max-width: 900px)': {
                    gridTemplateColumns: 'repeat(2, 1fr)',
                },
                '@media (max-width: 600px)': {
                    gridTemplateColumns: '1fr',
                },
            }}
        >
            {cardsContent.map((cardContent, idx) => (
                <Card variant="outlined" key={idx}>
                    <CardComponent category={cardContent.category} title={cardContent.title} redirectPath={cardContent.redirectPath} />
                </Card>
            ))}
        </Box>
    )
}

export default StocksReport
