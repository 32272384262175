import axios from '../../axios'
import { url as apiUrl } from './url.service'
import { getJwt } from './user.service'
const url = `${apiUrl}/rawMaterial`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addRawMaterial = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllRawMaterial = () => {
    return axios.get(`${url}`)
}

export const getRawMaterialById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deleteRawMaterial = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getStockLocation = async (query) => {
    return await axios.get(`${url}/getStockLocation?${query}`)
}

export const updateRawMaterial = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const getRawMaterialRequest = async () => {
    return axios.get(`${url}/getRawMaterialRequest`)
}

export const acceptRawMaterial = async (obj) => {
    return axios.post(`${url}/acceptRawMaterialRequest`, obj)
}

export const rejectRawMaterial = async (obj) => {
    return axios.post(`${url}/rejectRawMaterialRequest`, obj)
}

export const getRawMaterialStockById = async (id) => {
    return axios.get(`${url}/getRawMaterialStockByPartId/${id}`)
}
export const setStockLocation = async (obj) => {
    return await axios.post(`${url}/setStockLocation`, obj)
}
export const issueRawMaterialById = async (id) => {
    return await axios.get(`${url}/issueRawMaterialById/${id}`)
}

export const getDailyStockReportFromDates = async (query) => {
    return axios.get(`${url}/getDailyStockReportFromDates?${query}`)
}

export const getCurrentStockReportFromDates = async (query) => {
    return axios.get(`${url}/getCurrentStockReportFromDates?${query}`)
}

export const generateExcelForDailyStockReport = (query) => {
    const downloadExcel = () => window.open(`${url}/generateExcelForDailyStockReport?${query}`)

    const fetchData = async () => {
        try {
            return axios.get(`${url}/generateExcelForDailyStockReport?${query}&format=json`)
        } catch (error) {
            console.error('erro:', error)
        }
    }

    return {
        downloadExcel,
        fetchData,
    }
}

export const getItemWiseDailyReport = async (query) => {
    return axios.get(`${url}/getItemWiseDailyReport?${query}`)
}
// export const getItemWiseDailyReport = (query) => {
//     const downloadExcel = () => window.open(`${url}/getItemWiseDailyReport?${query}`)

//     const fetchData = async () => {
//         try {
//             return axios.get(`${url}/getItemWiseDailyReport?${query}&format=json`)
//         } catch (error) {
//             console.error('error:', error)
//         }
//     }

//     return {
//         downloadExcel,
//         fetchData,
//     }
// }

export const generateExcelForCurrentStockReport = async (query) => {
    window.open(`${url}/generateExcelForCurrentStockReport?${query}`)
}

export const getSingleRawMaterialReport = async (query) => {
    window.open(`${url}/getSingleRawMaterialReport?${query}`)
}

export const downloadSampleStockData = async () => {
    window.open(`${url}/downloadSampleExcelForStock`)
}

export const uploadRawMaterialStock = async (obj) => {
    return axios.post(`${url}/uploadStockExcel`, obj)
}

export const getRawMaterialStockLevels = async (page = 1, limit = 10) => {
    return axios.get(`${url}/getRawMaterialStockLevels`, {
        params: { page, limit },
    })
}

export const getStockBalanceSheetFromDates = async (query) => {
    return axios.get(`${url}/getStockBalanceSheetFromDates?${query}`)
}

export const getMonthlyStockReport = async (query) => {
    return axios.get(`${url}/getMonthlyStockReport?${query}`)
}

export const getAllMonthlyStockReport = async (query) => {
    return axios.get(`${url}/getAllMonthlyStockReport?${query}`)
}

export const getRawItemsStockReport = async (query) => {
    return axios.get(`${url}/getRawItemsStockReport?${query}`)
}
