import { Grid } from '@mui/material';
import { getMachineById } from 'app/services/machine.service';
import { toastError } from 'app/utils/toastUtils';
import { QRCodeSVG } from 'qrcode.react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MachineQr = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const data = queryParams.get('data') || 'No data';
    
    const [machineData,setMachineData]=useState({})
    const handleGetMachineData = async () => {
        try {
            let { data: res } = await getMachineById(data)
            if (res.data) {
                console.log(res.data)
                // console.log(productArr)
                setMachineData(res.data)
            }

        } catch (error) {
            toastError(error)
        }
    }


    useEffect(() => {
        handleGetMachineData()
    }, [data])

    return (
        // <Container>
        //     <Box sx={{ textAlign: 'center', mt: 4 }}>
        //         <Typography variant="h4" gutterBottom>
        //             Machine QR Code
        //         </Typography>
        //         <QRCodeSVG value={data} />
        //         <Typography variant="body1" sx={{ mt: 2 }}>
        //             Spec
        //         </Typography>
        //     </Box>
        // </Container>

        <div key={data} className="center_print" style={{ pageBreakAfter: 'always', height: "100%", display: "grid", placeItems: "center" }}>
            <div>
                <QRCodeSVG style={{alignSelf:'center'}} value={data} />
                <Grid container>
                    <Grid item xs={12}>
                        <span style={{ textAlign: 'center', color: "#000" }}>M/C No : {machineData?.identificationNo}</span>
                        <br />
                        <span style={{ textAlign: 'center', color: "#000" }}>SPEC : {machineData?.capacityOrSpec}</span>

                    </Grid>
                    <Grid item xs={12}>
                        <span style={{ textAlign: 'center', color: "#000" }}>Location : {machineData?.location}</span>
                        <br />
                        <span style={{ textAlign: 'center', color: "#000" }}>Type : {machineData?.machineType}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <span style={{ textAlign: 'center', color: "#000" }}>Name : {machineData?.name}</span>
                        {/* <br /> */}
                        {/* <span style={{ textAlign: 'center', color: "#000" }}>SUPPLIER : {el?.supplierName.slice(0, 20)}...</span> */}

                    </Grid>
                    
                </Grid>
            </div>
        </div>
    );
};

export default MachineQr;