import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'

import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import moment from 'moment'
import { toastError } from 'app/utils/toastUtils'
import { getAllRawMaterial, getMonthlyStockReport } from 'app/services/rawMaterial.service'

import Select from 'react-select'
export default function DailyStockReport() {
    const [selectedMonths, setSelectedMonths] = useState([])
    const [selectedYear, setSelectedYear] = useState(moment().year())
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([])
    const [monthlyReportData, setMonthlyReportData] = useState([])
    const [endYear, setEndYear] = useState(moment().year())

    const years = Array.from({ length: 5 }, (_, i) => ({
        value: moment().year() - i,
        label: String(moment().year() - i),
    }))
    const months = moment.months().map((month, index) => ({
        value: index,
        label: month,
    }))
    const getDateRange = () => {
        if (selectedMonths.length === 0) return null
        const sortedMonths = [...selectedMonths].sort((a, b) => a.value - b.value)

        const actualStartYear = Math.min(selectedYear, endYear)
        const actualEndYear = Math.max(selectedYear, endYear)

        const startDate = moment().year(actualStartYear).month(sortedMonths[0].value).startOf('month').format('YYYY-MM-DD')

        const endDate = moment()
            .year(actualEndYear)
            .month(sortedMonths[sortedMonths.length - 1].value)
            .endOf('month')
            .format('YYYY-MM-DD')

        return { startDate, endDate }
    }

    const handleGetRawMaterials = async () => {
        try {
            const { data: res } = await getAllRawMaterial()
            if (res) {
                console.log('Fetched Raw Materials:', res)
                const tempArr = res.data.map((el) => ({
                    label: el.name,
                    value: el._id,
                }))
                setRawMaterialArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetMonthlyReport = async () => {
        try {
            if (selectedMonths.length === 0) {
                toastError('select at least one month')
                return
            }
            if (!selectedRawMaterialArr || selectedRawMaterialArr.length === 0) {
                toastError('select at least one material')
                return
            }

            const dateRange = getDateRange()
            if (!dateRange) return

            let query = `startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`
            if (selectedRawMaterialArr && selectedRawMaterialArr.every((el) => el.value !== '')) {
                query += `&partIdArr=${JSON.stringify(selectedRawMaterialArr.map((el) => el.value))}`
            }
            const { data: res } = await getMonthlyStockReport(query)
            if (res) {
                console.log(res.data)
                setMonthlyReportData(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const groupedByMaterial = monthlyReportData.reduce((acc, item) => {
        const key = item.partNo
        if (!acc[key]) acc[key] = []
        acc[key].push(item)
        return acc
    }, {})

    useEffect(() => {
        handleGetRawMaterials()
    }, [])

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const TableHeadCell = styled(TableCell)(({ theme }) => ({
        backgroundColor: '#ededed',
        fontWeight: 'bold',
        color: '#000',
        fontSize: '14px',
        padding: '12px 16px',
        borderRight: '1px solid #bbb',
        borderBottom: '2px solid #999aaa',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const TableBodyCell = styled(TableCell)(({ theme }) => ({
        padding: '8px 16px',
        borderRight: '1px solid #ddd',
        textAlign: 'center',
        '&:first-of-type': {
            borderLeft: '1px solid #ddd',
        },
    }))

    const StyledTable = styled(Table)(({ theme }) => ({
        borderCollapse: 'collapse',
        '& .MuiTableRow-root:nth-of-type(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& .MuiTableRow-root:hover': {
            backgroundColor: '#f5f5f5',
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Monthly Stock Report'} CustomStyle={{ marginBottom: '2rem' }}>
                <Typography sx={{ mt: 3 }}>Select Month and Year</Typography>

                <Grid container spacing={3}>
                    <Grid item md={3}>
                        <label>Start Year</label>
                        <Select value={years.find((y) => y.value === selectedYear)} options={years} onChange={(selected) => setSelectedYear(selected.value)} placeholder="Select Start Year" />
                    </Grid>
                    <Grid item md={3}>
                        <label>End Year</label>
                        <Select value={years.find((y) => y.value === endYear)} options={years} onChange={(selected) => setEndYear(selected.value)} placeholder="Select End Year" />
                    </Grid>
                    <Grid item md={3}>
                        <label>Months</label>
                        <Select isMulti value={selectedMonths} options={months} onChange={setSelectedMonths} placeholder="Select Months" />
                    </Grid>
                    <Grid item md={3}>
                        <label>Raw Material</label>
                        <Select isMulti value={selectedRawMaterialArr} options={rawMaterialArr} onChange={(e) => setSelectedRawMaterialArr(e)} placeholder="Raw Materials" />
                    </Grid>
                </Grid>

                <Button onClick={handleGetMonthlyReport} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Search
                </Button>
            </SimpleCard>

            {Object.entries(groupedByMaterial).map(([partNo, data]) => (
                <SimpleCard key={partNo} title={`Monthly Stock Summary - ${data[0].partName}`} sx={{ mt: 3 }} CustomStyle={{ marginY: '1rem' }}>
                    <Typography sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
                        From {moment(getDateRange()?.startDate).format('DD MMM YYYY')}, To {moment(getDateRange()?.endDate).format('DD MMM YYYY')}
                    </Typography>
                    <Box sx={{ overflow: 'auto', mt: 2, borderRadius: 1 }}>
                        <StyledTable sx={{ minWidth: 650 }} aria-label="monthly stock report table">
                            <TableHead>
                                <TableRow>
                                    <TableHeadCell rowSpan={2}>Month</TableHeadCell>
                                    <TableHeadCell colSpan={2}>Inward</TableHeadCell>
                                    <TableHeadCell colSpan={2}>Outward</TableHeadCell>
                                    <TableHeadCell colSpan={2}>Balance</TableHeadCell>
                                </TableRow>
                                <TableRow>
                                    <TableHeadCell>Qty.</TableHeadCell>
                                    <TableHeadCell>Amount</TableHeadCell>
                                    <TableHeadCell>Qty.</TableHeadCell>
                                    <TableHeadCell>Amount</TableHeadCell>
                                    <TableHeadCell>Qty.</TableHeadCell>
                                    <TableHeadCell>Amount</TableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* Opening Stock Row */}
                                {data[0] && (
                                    <TableRow>
                                        <TableBodyCell colSpan={5}>Opening Stock</TableBodyCell>
                                        <TableBodyCell>{data[0].openingStock.quantity?.toFixed(2) || '0.00'}</TableBodyCell>
                                        <TableBodyCell>{(data[0].openingStock.rate * data[0].openingStock.quantity)?.toFixed(2) || '0.00'}</TableBodyCell>
                                    </TableRow>
                                )}
                                {/* Monthly Data Rows */}
                                {data.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableBodyCell>{row.monthYear}</TableBodyCell>
                                        {/* inward */}
                                        <TableBodyCell>{row.inward.quantity?.toFixed(2) || '0.00'}</TableBodyCell>
                                        <TableBodyCell>{row.inward.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                        {/* outward */}
                                        <TableBodyCell>{row.outward.quantity?.toFixed(2) || '0.00'}</TableBodyCell>
                                        <TableBodyCell>{row.outward.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                        {/* balance */}
                                        <TableBodyCell>{row.balance.quantity?.toFixed(2) || '0.00'}</TableBodyCell>
                                        <TableBodyCell>{row.balance.amount?.toFixed(2) || '0.00'}</TableBodyCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </StyledTable>
                    </Box>
                </SimpleCard>
            ))}
        </Container>
    )
}
