import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { getAllProductRecipe, deleteProductRecipe } from '../../../services/ProductRecipe.service'
import { useNavigate } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function ViewProductRecipe() {
    const [recipeData, setRecipeData] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        fetchRecipes()
    }, [])

    const fetchRecipes = async () => {
        try {
            let { data: res } = await getAllProductRecipe()
            if (res) {
                setRecipeData(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this recipe?')) {
                const res = await deleteProductRecipe(id)
                if (res.data?.message) toastSuccess(res.data.message)
                fetchRecipes()
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleUpdate = (id) => {
        navigate(`/admin/ProductRecipe/Add?id=${id}`)
    }

    return (
        <Container>
            <SimpleCard title="BOM">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {recipeData.map((recipe, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{idx + 1}</TableCell>
                                <TableCell>{recipe.productName}</TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Button variant="outlined" color="primary" onClick={() => handleUpdate(recipe._id)}>
                                            Update
                                        </Button>
                                        <Button variant="outlined" color="error" onClick={() => handleDelete(recipe._id)}>
                                            Delete
                                        </Button>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
