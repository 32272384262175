import { styled } from '@mui/material/styles'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, TextField, Container, Button } from '@mui/material'
import { Box } from '@mui/system'
import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { getAllRawMaterial, getStockBalanceSheetFromDates } from 'app/services/rawMaterial.service'
import { toastError } from 'app/utils/toastUtils'
import Select from 'react-select'

const BorderedTableCell = styled(TableCell)({
    border: '1px solid rgba(0, 0, 0, 1)',
    padding: '6px 16px',
})

const AllItemsReport = () => {
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().add({ day: 1 }).format('YYYY-MM-DD'))
    const [rawMaterialArr, setRawMaterialArr] = useState([])
    const [selectedRawMaterialArr, setSelectedRawMaterialArr] = useState([])
    const [reportData, setReportData] = useState([])

    const handleGetRawMaterials = async () => {
        try {
            let { data: res } = await getAllRawMaterial()
            if (res) {
                let tempArr = [...res.data.map((el) => ({ label: el.name, value: el._id }))]
                setRawMaterialArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleSearch = async () => {
        try {
            let query = `startDate=${startDate}&endDate=${endDate}`
            if (selectedRawMaterialArr && selectedRawMaterialArr.length > 0) {
                const partIdsJson = JSON.stringify(selectedRawMaterialArr.map((el) => el.value))
                query += `&partIdArr=${partIdsJson}`
            }
            const { data: res } = await getStockBalanceSheetFromDates(query)
            if (res.success && res.data) {
                const groupedData = res.data.reduce((acc, item) => {
                    const monthYear = moment(item.reportDate).format('YYYY-MM')
                    if (!acc[monthYear]) {
                        acc[monthYear] = []
                    }
                    acc[monthYear].push(item)
                    return acc
                }, {})
                setReportData(groupedData)
                console.log(groupedData)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetRawMaterials()
    }, [])

    const daysArray = Array.from({ length: 31 }, (_, i) => i + 1)

    return (
        <Container>
            <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item md={3}>
                    <TextField type="date" onChange={(e) => setStartDate(e.target.value)} value={startDate} label="Start Date" sx={{ width: 250, mt: 2 }} InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item md={3}>
                    <TextField InputLabelProps={{ shrink: true }} type="date" onChange={(e) => setEndDate(e.target.value)} value={endDate} label="End Date" sx={{ width: 250, mt: 2, ml: 2 }} />
                </Grid>
                <Grid item md={3}>
                    <label>Raw Material</label>
                    <Select isMulti value={selectedRawMaterialArr} options={rawMaterialArr} placeholder="Raw Materials" onChange={(e) => setSelectedRawMaterialArr(e)} />
                </Grid>
            </Grid>

            <Button onClick={handleSearch} variant="contained" color="primary" sx={{ my: 2 }}>
                Search
            </Button>

            {Object.entries(reportData)
                .sort()
                .map(([monthYear, monthData]) => (
                    <TableContainer key={monthYear} component={Paper} sx={{ overflowX: 'auto', maxWidth: '100vw', mb: 4,width:'100vw' }}>
                        <SimpleCard title={`All Items Report - ${moment(monthYear).format('MMMM YYYY')}`}>
                            <Box sx={{ mt: 2, borderRadius: 1, overflowX: 'auto' }}>
                                <Table sx={{ minWidth: 11000 }} size="medium">
                                    <TableHead style={{ backgroundColor: '#dee0e3' }}>
                                        {/* main headers */}
                                        <TableRow>
                                            <BorderedTableCell rowSpan={2}>Sr. No</BorderedTableCell>
                                            <BorderedTableCell rowSpan={2}>Location</BorderedTableCell>
                                            <BorderedTableCell rowSpan={2}>Particulars</BorderedTableCell>
                                            <BorderedTableCell rowSpan={2}>Item Code</BorderedTableCell>
                                            <BorderedTableCell rowSpan={2}>Supplier Name</BorderedTableCell>

                                            <BorderedTableCell>Opening Bal</BorderedTableCell>

                                            <BorderedTableCell colSpan={31}>Inword Detail</BorderedTableCell>

                                            <BorderedTableCell colSpan={4}>Price</BorderedTableCell>

                                            <BorderedTableCell>PPC Plan</BorderedTableCell>

                                            <BorderedTableCell colSpan={4}>STOCK DETAIL</BorderedTableCell>

                                            <BorderedTableCell colSpan={31}>ISSUE/OUTWARD DETAIL</BorderedTableCell>

                                            <BorderedTableCell colSpan={12}>STR-F-001A, REV-00, 10.10.2021, EFF.DATE-12.10.2021</BorderedTableCell>
                                        </TableRow>

                                        {/* sub headings */}
                                        <TableRow>
                                            {/* opening bal date */}
                                            <BorderedTableCell align="center">Date</BorderedTableCell>

                                            {/* inward detail dates */}
                                            {daysArray.map((day) => (
                                                <BorderedTableCell key={`inward-${day}`} align="center" sx={{ minWidth: 50 }}>
                                                    {day}
                                                </BorderedTableCell>
                                            ))}

                                            {/* price Details */}
                                            <BorderedTableCell align="center">@Rate</BorderedTableCell>
                                            <BorderedTableCell align="center">Fixed Price MINIMUM</BorderedTableCell>
                                            <BorderedTableCell align="center">Difference</BorderedTableCell>
                                            <BorderedTableCell align="center">TOTAL INVENTORY VALUE</BorderedTableCell>

                                            {/* PPC */}
                                            <BorderedTableCell align="center">PPC PLAN</BorderedTableCell>

                                            {/* stock detail */}
                                            <BorderedTableCell align="center">TOTAL</BorderedTableCell>
                                            <BorderedTableCell align="center">BALANCE</BorderedTableCell>
                                            <BorderedTableCell align="center">Consumed</BorderedTableCell>
                                            <BorderedTableCell align="center">CONSUMED AMOUNT</BorderedTableCell>

                                            {/* issue/outward detail days */}
                                            {daysArray.map((day) => (
                                                <BorderedTableCell key={`outward-${day}`} align="center" sx={{ minWidth: 50 }}>
                                                    {day}
                                                </BorderedTableCell>
                                            ))}

                                            {/* additional */}
                                            <BorderedTableCell align="center">BALANCE</BorderedTableCell>
                                            <BorderedTableCell align="center">Physical Stock</BorderedTableCell>
                                            <BorderedTableCell align="center">Diff.</BorderedTableCell>
                                            <BorderedTableCell align="center">Monthly Average</BorderedTableCell>
                                            <BorderedTableCell align="center">DAY WISE CONS.</BorderedTableCell>
                                            <BorderedTableCell align="center">Min. Quantity</BorderedTableCell>
                                            <BorderedTableCell align="center">MAXIMUM</BorderedTableCell>
                                            <BorderedTableCell align="center">RE-ORDER LEVEL AS PER MOQ</BorderedTableCell>
                                            <BorderedTableCell align="center">PO ORDER</BorderedTableCell>
                                            <BorderedTableCell align="center">Received Material as Per PO</BorderedTableCell>
                                            <BorderedTableCell align="center">Pending Material As Per PO & Req.</BorderedTableCell>
                                            <BorderedTableCell align="center">Requirement for FEB.-24</BorderedTableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {monthData.map((item, index) => (
                                            <TableRow key={item._id || index}>
                                                <BorderedTableCell>{index + 1}</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>{item.partName}</BorderedTableCell>
                                                <BorderedTableCell>{item.partNo}</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>{item.openingStock}</BorderedTableCell>

                                                {/* inward details */}
                                                {daysArray.map((day) => {
                                                    const currentDate = moment(item.reportDate).date()
                                                    return <BorderedTableCell key={`inward-${day}`}>{day === currentDate ? item.stockIn : '-'}</BorderedTableCell>
                                                })}

                                                {/* price details */}
                                                <BorderedTableCell>{item.rmRate}</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>

                                                {/* PPC */}
                                                <BorderedTableCell>-</BorderedTableCell>

                                                {/* stock details */}
                                                <BorderedTableCell>
                                                    {daysArray.reduce((total, day) => {
                                                        const currentDate = moment(item.reportDate).date()
                                                        const dayStockIn = day === currentDate ? item.stockIn : 0
                                                        return total + dayStockIn * item.rmRate
                                                    }, 0)}
                                                </BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>

                                                {/* outward details */}
                                                {daysArray.map((day) => {
                                                    const currentDate = moment(item.reportDate).date()
                                                    return <BorderedTableCell key={`inward-${day}`}>{day === currentDate ? item.stockOut : '-'}</BorderedTableCell>
                                                })}

                                                {/* additional details */}
                                                <BorderedTableCell>{item.stockOut}</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                                <BorderedTableCell>-</BorderedTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </SimpleCard>
                    </TableContainer>
                ))}
        </Container>
    )
}

export default AllItemsReport
